import { SetStateAction, useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IMeta, ITextBookIndex, ITextBook, IUser, IBrand } from "../common";
import { logger } from "../logger";
import "../common.scss";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import DatePicker from "@gsebdev/react-simple-datepicker/dist/DatePicker";
import "../library/LibrarySearch.scss";
import PopupConfirm from "../contents/PopupConfirm";
import { CheckBox } from "@mui/icons-material";

export interface IServerInfo {
    code: string;
    name: string;
    is_active: boolean;
}

interface IPopupDeployProcess {
    deployContent: any;
    onClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (view: boolean) => void;    
}

function PopupDeployProcess(props: IPopupDeployProcess) {

    const [brandList, setBrandList] = useState<IBrand[]>([]);
    const [selectedBrandList, setSelectedBrandList] = useState<IBrand[]>([]);
    const [viewConfirm, setViewConfirm] = useState(false);    
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [isStarted, setIsStarted] = useState(false);    
    const [serverList, setServerList] = useState<IServerInfo[]>([]);
    const [contentTypeList, setContentTypeList] = useState<string[]>([]);

    const [isMultiDeploy, setIsMultiDeploy] = useState(false);    

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            if(props.deployContent) {
                setIsMultiDeploy(false);
            } else {
                setIsMultiDeploy(true);
            }

            getBrandList();
            getDeployServer();

            setContentTypeList(["교과서", "세트지", "아티클"]);

            // setServerList([
            //     {code: 0, name: "T"},
            //     {code: 1, name: "S"},
            //     {code: 2, name: "B"},
            // ]);
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(serverList) {
            // logger.log("serverList", serverList);
        }
    }, [serverList])
    
    function onClose() {
        props.onClose();
    }

    async function getBrandList() {
        props.showLoading(true);
        const brandList = await util.getBrandList();
        props.showLoading(false);
        setBrandList(brandList);
    }

    async function getDeployServer() {
        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }
        const serverList = await util.getDeployServer(brand.id);
        logger.log("getDeployServer", serverList);
        setServerList(serverList);
    }

    async function onStartDeploy() {
        setViewConfirm(true);
        setTitle((isMultiDeploy ? "일괄" : "단일") + " 배포진행 확인");
        setDesc("<b><span style=\"color:red\">" + (isMultiDeploy ? "일괄" : "단일") + "</span></b>" + " 배포를 진행하시겠습니까?");
    }

    async function onOK() {
        onCloseConfirm();

        setIsStarted(true);

        const _pendingBrandIds : number[] = [];
        const _startBrandIds : number[] = [];

        const _selectedBrandList : IBrand[] = [];
        brandList.map((brand, idx) => {
            const check_ = document.getElementById("check_"+idx) as HTMLInputElement;
            if(check_.checked) {
                _selectedBrandList.push(brand);        
                if(brand.id) {
                    _startBrandIds.push(brand.id);
                }
            } else {
                if(brand.id) {
                    _pendingBrandIds.push(brand.id);
                }
            }
        });

        setSelectedBrandList(_selectedBrandList);

        const _selectedServerList : string[] = [];
        serverList.map((server, idx) => {
            const check_server_ = document.getElementById("check_server_"+idx) as HTMLInputElement;
            if(check_server_.checked) {
                _selectedServerList.push(server.code);        
            }
        });

        const _selectedTypeList : string[] = [];
        contentTypeList.map((type, idx) => {
            const check_type_ = document.getElementById("check_type_"+idx) as HTMLInputElement;
            if(check_type_.checked) {
                let _type = "";
                if(type === "교과서") {
                    _type = "textbook";
                } else if(type === "아티클") {
                    _type = "article";
                } else if(type === "세트지") {
                    _type = "sets";
                }
                _selectedTypeList.push(_type);        
            }
        });

        if(_selectedTypeList.length === 0) {
            props.showModal("", "콘텐츠 구분은 1개 이상이 필요합니다.");
            return;
        } 

        const data = {
            // "pendingBrandIds": _pendingBrandIds,
            "startBrandIds": _startBrandIds,
            "serverCodeList": _selectedServerList,
            "targetList": _selectedTypeList,
            "description": util.getUserInfo("uid") + "_" + util.getDate().replaceAll(".", ""),        
            "contentsHistoryList": props.deployContent ? [props.deployContent] : undefined,
        }

        // logger.log("deployChangeStatus", data);

        props.showLoading(true);

        const result = await util.deployChangeStatus(data);                    
        logger.log("deployChangeStatus", data, result);
        if(result.succeed === false) {
            props.showModal("", result.data);
        }

        props.showLoading(false);

        logger.log("onStartDeploy", _selectedBrandList, _selectedServerList);

        // props.showModal("", "배포가 완료되었습니다.");
        
        onClose();
    }
    
    function onCloseConfirm() {
        setViewConfirm(false);
    }

    return (
        
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner" style={{padding:0}}>
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box" style={{/*maxWidth:"60rem", maxHeight:"60rem", minHeight:"40rem"*/padding: "5rem"}}>
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={onClose}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">{(isMultiDeploy ? "일괄" : "단일") + " 배포진행"}</h2>

                            {/* <!-- table --> */}
                            <div className="ml-5 mr-5 mt-5">
                                <table>
                                    <caption className="sr-only">리스트</caption>
                                    <tbody>
                                        <tr style={{display: isMultiDeploy ? "" : "none"}}>
                                            <th className="w-60 font-bold text-center">콘텐츠 구분​</th>
                                            <td className="w-avail">
                                                <span className="flex items-center w-avail" style={{padding:"0.5rem"}}>
                                                    <input id={"check_all_type"} type="checkbox" defaultChecked onChange={(e) => {
                                                        contentTypeList.map((_type, idx) => {
                                                            const check_type_ = document.getElementById("check_type_"+idx) as HTMLInputElement;
                                                            check_type_.checked = e.target.checked;    
                                                        })                                                            
                                                    }} />
                                                    <label htmlFor="chd-advise1" className="ml-3 w-16 font-bold text-left">전체</label>

                                                    {contentTypeList.map((item, idx) => {
                                                    let _name = item;
                                                    return(
                                                        <span className="flex items-center" key={idx} style={{padding:"0.5rem"}}>
                                                            <input id={"check_type_"+idx} type="checkbox" defaultChecked onChange={(e) => {
                                                                let chkCnt = 0;
                                                                contentTypeList.map((_type, idx) => {
                                                                    const check_type_ = document.getElementById("check_type_"+idx) as HTMLInputElement;
                                                                    if(check_type_.checked) {
                                                                        chkCnt++;
                                                                    }
                                                                });
                                                                const check_all_type = document.getElementById("check_all_type") as HTMLInputElement;                                                           
                                                                if(contentTypeList.length === chkCnt) {
                                                                    check_all_type.checked = true;
                                                                } else {
                                                                    check_all_type.checked = false;
                                                                }
                                                            }} />
                                                            <label htmlFor="chd-advise1" className="ml-3 font-bold text-left" onClick={() => {
                                                                // const check_ = document.getElementById("check_"+idx) as HTMLInputElement;
                                                                // if(check_) {
                                                                //     check_.checked = !check_.checked;
                                                                // }
                                                            }}>{_name}</label>
                                                        </span>                
                                                    );
                                                })}
                                                </span>                
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="w-60 font-bold text-center">서버</th>
                                            <td className="w-avail">
                                                <span className="flex items-center w-avail" style={{padding:"0.5rem"}}>
                                                    <input id={"check_all_server"} type="checkbox" defaultChecked onChange={(e) => {
                                                        serverList.map((_svr, idx) => {
                                                            if(_svr.is_active) {
                                                                const check_ = document.getElementById("check_server_"+idx) as HTMLInputElement;
                                                                check_.checked = e.target.checked;    
                                                            }
                                                        })                                                            
                                                    }} />
                                                    <label htmlFor="chd-advise1" className="ml-3 w-16 font-bold text-left">전체</label>

                                                    {serverList.map((item, idx) => {
                                                    let _name = item.name;
                                                    return(
                                                        <span className="flex items-center" key={idx} style={{padding:"0.5rem"}}>
                                                            <input id={"check_server_"+idx} type="checkbox" defaultChecked={item.is_active} disabled={!item.is_active} onChange={(e) => {
                                                                let chkCnt = 0;
                                                                let cntActive = 0;
                                                                serverList.map((_svr, idx) => {
                                                                    if(_svr.is_active) {
                                                                        cntActive++;
                                                                    }
                                                                    const check_server_ = document.getElementById("check_server_"+idx) as HTMLInputElement;
                                                                    if(check_server_.checked) {
                                                                        chkCnt++;
                                                                    }
                                                                });
                                                                const check_all_server = document.getElementById("check_all_server") as HTMLInputElement;                                                           
                                                                if(cntActive === chkCnt) {
                                                                    check_all_server.checked = true;
                                                                } else {
                                                                    check_all_server.checked = false;
                                                                }
                                                            }} />
                                                            <label htmlFor="chd-advise1" className="ml-3 font-bold text-left" onClick={() => {
                                                                // const check_ = document.getElementById("check_"+idx) as HTMLInputElement;
                                                                // if(check_) {
                                                                //     check_.checked = !check_.checked;
                                                                // }
                                                            }}>{_name}</label>
                                                        </span>                
                                                    );
                                                })}

                                                </span>                
                                            </td>
                                        </tr>                                        
                                        <tr>
                                            <th className="w-60 font-bold text-center">브랜드</th>
                                            <td className="">
                                                <ul>
                                                    <li className="flex items-center" style={{display: "none", padding:"0.5rem"}}>
                                                        <input id={"check_all"} type="checkbox" defaultChecked onChange={(e) => {
                                                            brandList.map((_, idx) => {
                                                                const check_ = document.getElementById("check_"+idx) as HTMLInputElement;
                                                                check_.checked = e.target.checked;
                                                            })                                                            
                                                        }} />
                                                        <label htmlFor="chd-advise1" className="ml-3 w-36 font-bold text-left">전체</label>
                                                    </li>                
                                                    {brandList.map((item, idx) => {

                                                        let _name = item.name;
                                                        if(isStarted) {
                                                            if(!selectedBrandList.includes(item)) {
                                                                _name = _name + " (" + util.getDate() + " 보류)";
                                                            }
                                                        }

                                                        const brand = util.getCurrentBrand();
                                                        if(!brand) {
                                                            return;
                                                        }
                                                        const bView = brand.id === item.id;

                                                        return(
                                                            <li className="flex items-center" key={idx} style={{display: bView ? "" :"none", padding:"0.5rem"}}>
                                                                <input id={"check_"+idx} type="checkbox" defaultChecked={bView} disabled onChange={(e) => {
                                                                    let chkCnt = 0;
                                                                    brandList.map((_, idx) => {
                                                                        const check_ = document.getElementById("check_"+idx) as HTMLInputElement;
                                                                        if(check_.checked) {
                                                                            chkCnt++;
                                                                        }
                                                                    });
                                                                    const check_all = document.getElementById("check_all") as HTMLInputElement;                                                           
                                                                    if(brandList.length === chkCnt) {
                                                                        check_all.checked = true;
                                                                    } else {
                                                                        check_all.checked = false;
                                                                    }
                                                                }} />
                                                                <label htmlFor="chd-advise1" className="ml-3 w-36 font-bold text-left" onClick={() => {
                                                                    // const check_ = document.getElementById("check_"+idx) as HTMLInputElement;
                                                                    // if(check_) {
                                                                    //     check_.checked = !check_.checked;
                                                                    // }
                                                                }}>{_name}</label>
                                                            </li>                
                                                        );
                                                    })}
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="py-5 text-center">
                                <button type="button" className="btn-white" style={{width: "10rem"}} onClick={() => {
                                    onStartDeploy();
                                }}>{(isMultiDeploy ? "일괄" : "단일") + " 배포 시작"}</button>
                            </div>                    

                            {/* <!-- .//table -->	 */}                
                            
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>                    
                </div>
            </div>

            <PopupConfirm view={viewConfirm} title={title} description={desc} onClose={onCloseConfirm} onOk={onOK} showModal={props.showModal} />

        </div>        
    );
}
  
export default PopupDeployProcess;
