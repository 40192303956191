import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { ISet, IBrand, IMenu, IMeta, S3Server, EDITOR_HOST } from "../common";
import { logger } from '../logger';
import { DatePicker } from "@gsebdev/react-simple-datepicker";
import MyModal from "../MyModal";
import PopupConfirm from "./PopupConfirm";
import MyButton from "../MyButton";
import PopupContentsUpdateHistory from "./PopupContentsUpdateHistory";

interface IContentsSetList {
    brand: IBrand;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}
  
function ContentsSetList(props: IContentsSetList) {
    
    const [list, setList] = useState([] as any);
    const [viewType, setViewType] = useState("");  //s:small, b:big
    const [listCount, setListCount] = useState(0);

    const [viewList, setViewList] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);

    const [curriBook, setCurriBook] = useState([] as any);
    const [curriUnit1, setCurriUnit1] = useState([] as any);
    const [curriUnit2, setCurriUnit2] = useState([] as any);
    const [curriUnit3, setCurriUnit3] = useState([] as any);
    const [curriUnit4, setCurriUnit4] = useState([] as any);
    const [curriUnit5, setCurriUnit5] = useState([] as any);

    const [curriDepth, setCurriDepth] = useState(0);
    const [bookDescendantsList, setBookDescendantsList] = useState([] as any);

    const [modalShow, setModalShow] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null as any);
    
    const [viewConfirmDelete, setViewConfirmDelete] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteDesc, setDeleteDesc] = useState("");

    const [viewContentsUpdateHistory, setViewContentsUpdateHistory] = useState(false);

    const currMenu = util.getCurrentMenu();

    const numOfViewPage = 10;

    const curriInfo = util.getCurrentCurriInfo();

    const CL_html_curriUnit1 = document.getElementById("CL_curriUnit1") as HTMLSelectElement;
    const CL_html_curriUnit2 = document.getElementById("CL_curriUnit2") as HTMLSelectElement;
    const CL_html_curriUnit3 = document.getElementById("CL_curriUnit3") as HTMLSelectElement;
    const CL_html_curriUnit4 = document.getElementById("CL_curriUnit4") as HTMLSelectElement;
    const CL_html_curriUnit5 = document.getElementById("CL_curriUnit5") as HTMLSelectElement;

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            // setViewList(true);
            setViewType("s");

            const _pageNum = localStorage.getItem("Contents_numOfPage");
            const pagenum = document.getElementById("pagenum") as HTMLSelectElement;
            if(pagenum) {
                if(_pageNum) {
                    pagenum.value = _pageNum;
                } else {
                    pagenum.value = "10";                
                }    
            }

            const viewlist1 = document.getElementById("rd-viewlist1") as HTMLInputElement;
            viewlist1.checked = true;

            const div = document.getElementsByClassName("SG-datepicker__input-container");
            if(div) {
                
                const new_CL_sdate = document.getElementById("new_CL_sdate") as HTMLInputElement;
                if(!new_CL_sdate) {
                    let node = document.createElement("input");
                    node.id = "new_CL_sdate";
                    node.type = "text";
                    node.placeholder = "시작일";
                    node.value = ""
                    node.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node.addEventListener("keydown", (e) => {
                        e.stopPropagation();                                  
                    })
        
                    div[0].appendChild(node)
    
                }

                const CL_sdate = document.getElementById("CL_sdate") as HTMLInputElement;
                if(CL_sdate) {
                    CL_sdate.style.display = "none";
                }

                const new_CL_edate = document.getElementById("new_CL_edate") as HTMLInputElement;
                if(!new_CL_edate) {
                    let node_e = document.createElement("input");
                    node_e.id = "new_CL_edate";
                    node_e.type = "text";
                    node_e.placeholder = "종료일";
                    node_e.value = ""
                    node_e.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node_e.addEventListener("keydown", (e) => {
                        e.stopPropagation();
                    })
        
                    div[1].appendChild(node_e)
                }

                const CL_edate = document.getElementById("CL_edate") as HTMLInputElement;
                if(CL_edate) {
                    CL_edate.style.display = "none";
                }
            }
        }
        bDone = true;
    }, [])

    let preBrand: common.IBrand | null = null;
    useEffect(() => {
        if(props.brand && props.brand !== preBrand) {
            logger.log("props.brand", props.brand);
            setCurriBook(curriInfo?.curriBook);
            refreshList();
        }
        preBrand = props.brand;
    }, [props.brand])

    const refreshList = () => {

        setSearchData(null);
        setList([]);
        setViewList(true);
        setListCount(0);

        // const brand = util.getCurrentBrand();
        // if(!brand || !brand.id) {
        //     return;
        // }
        // setCurrPage(1);

        // const data = {
        //     brand_id: brand.id,
        //     orderby_col: "id",
        //     orderby_dir: "desc",
        //     limit_page: 1,
        //     limit_pageItemCnt: localStorage.getItem("Contents_numOfPage") ? Number(localStorage.getItem("Contents_numOfPage")) : 10,
        // };
        // setSearchData(data);
        // _onSearch(data);

        // logger.log("refreshList", data);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = localStorage.getItem("Contents_numOfPage") ? Number(localStorage.getItem("Contents_numOfPage")) : 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        if(data) {
            // data.detail = true;
            data.is_active = true;
        }

        setViewList(false);

        const list = await util.getSetSearch(data);
        setList(list);
        logger.log("onSearch", data, list);
        setViewList(true);
        setListCount(0);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count;
                setListCount(totalCnt);
            }
            calcPage(totalCnt, currentPage);
        }

        props.showLoading(false);
    }

    function onSearch() {

        if(!curriInfo) {
            return;
        }

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }

        const CL_sdate = document.getElementById("new_CL_sdate") as HTMLInputElement;
        const CL_edate = document.getElementById("new_CL_edate") as HTMLInputElement;        
    
        let sdate = "";
        let edate = "";
        const arrSD = CL_sdate.value.split("-");
        const arrED = CL_edate.value.split("-");
        if(arrSD.length === 3 && arrED.length === 3) {
            sdate = CL_sdate.value;
            edate = CL_edate.value 
        }  

        const CL_curriBook = document.getElementById("CL_curriBook") as HTMLSelectElement;
        const CL_curriBook_val = curriBook[CL_curriBook.selectedIndex-1];

        const CL_html_curriUnit1_val = curriUnit1 ? (curriUnit1[CL_html_curriUnit1.selectedIndex-1] as IMeta) : undefined;
        const CL_html_curriUnit2_val = curriUnit2 ? (curriUnit2[CL_html_curriUnit2.selectedIndex-1] as IMeta) : undefined;
        const CL_html_curriUnit3_val = curriUnit3 ? (curriUnit3[CL_html_curriUnit3.selectedIndex-1] as IMeta) : undefined;
        const CL_html_curriUnit4_val = curriUnit4 ? (curriUnit4[CL_html_curriUnit4.selectedIndex-1] as IMeta) : undefined;
        const CL_html_curriUnit5_val = curriUnit5 ? (curriUnit5[CL_html_curriUnit5.selectedIndex-1] as IMeta) : undefined;

        const CL_public = document.getElementById("CL_public") as HTMLSelectElement;
        const CL_material = document.getElementById("CL_material") as HTMLSelectElement;
        const CL_type = document.getElementById("CL_type") as HTMLSelectElement;

        const CL_difficulty = document.getElementById("CL_difficulty") as HTMLSelectElement;

        const CL_keyword = document.getElementById("CL_keyword") as HTMLInputElement;        
        const CL_keyword_type = document.getElementById("CL_keyword_type") as HTMLInputElement;

        let search = "";
        if(CL_keyword) {
            search = CL_keyword.value;
        }
        let searchField = "";
        if(CL_keyword_type) {
            searchField = CL_keyword_type.value;
        }

        const data = {
            brand_id: brand.id,
            // name: CL_keyword && CL_keyword.value !== "" ? CL_keyword.value : undefined,
            search: search,
            searchField: searchField,

            reg_sdate: sdate,  
            reg_edate: edate,
            is_publicOpen: CL_public.value === "" ? undefined : CL_public.value === "1" ? true : false,
            is_materialOpen: CL_material.value === "" ? undefined : CL_material.value === "1" ? true : false,
            setCategory: CL_type.value === "" ? undefined : CL_type.value,
            difficulty: CL_difficulty.value === "" ? undefined : CL_difficulty.value,

            curriBook: CL_curriBook_val ? CL_curriBook_val.id : undefined,
            curriUnit1: CL_html_curriUnit1_val ? CL_html_curriUnit1_val.id : undefined,
            curriUnit2: CL_html_curriUnit2_val ? CL_html_curriUnit2_val.id : undefined,
            curriUnit3: CL_html_curriUnit3_val ? CL_html_curriUnit3_val.id : undefined,
            curriUnit4: CL_html_curriUnit4_val ? CL_html_curriUnit4_val.id : undefined,
            curriUnit5: CL_html_curriUnit5_val ? CL_html_curriUnit5_val.id : undefined,

            orderby_col: "id", 
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: localStorage.getItem("Contents_numOfPage") ? Number(localStorage.getItem("Contents_numOfPage")) : 10,
        };

        logger.log("onSearch", data);

        setSearchData(data);
        _onSearch(data);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onPrevPage", _listOfPage);
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    // setCurrPage(_currPage);    
                    onChangePage(_currPage);
                    // logger.log("onPrevPage", _currPage, _listOfPage);
                }
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onNextPage", _listOfPage);
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    // setCurrPage(_currPage);    
                    onChangePage(_currPage);
                    // logger.log("onPrevPage", _currPage, _listOfPage);
                }
            }
        }
    }

    function onChangeNumOf1Page(e: ChangeEvent<HTMLSelectElement>): void {
        const numOfPage = e.target.value;
        setCurrPage(1);
        localStorage.setItem("Contents_numOfPage", numOfPage);
        const _searchData = searchData;
        _searchData.limit_page = 1;
        _searchData.limit_pageItemCnt = numOfPage;
        _onSearch(_searchData);            
    }

    function onChangePage(page: number): void {
        setCurrPage(page);
        const _searchData = searchData;
        _searchData.limit_page = page;
        _onSearch(_searchData);
    }

    async function getDescendantsList(code: string) {

        props.showLoading(true);

        if(code === "0") {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        } else {
            const list = await util.getDescendantsList(code);

            const curriBook = list.find((item) => item.code === code);
            if(!curriBook) {
                return;
            }
            setBookDescendantsList(list);
    
            const _filtered_curriUnit1 = list.filter((item) => item.name === "curriUnit1");
            setCurriUnit1(_filtered_curriUnit1);
    
            let max_depth = 0;
            if(curriBook.max_depth) {
                max_depth = curriBook.max_depth;
            } else {
                list.map((item) => {
                    if(max_depth < item.depth) {
                        max_depth = item.depth;
                    }
                });
                max_depth = max_depth - 1;
            }
            setCurriDepth(max_depth);        

            logger.log("getDescendantsList", list, curriBook, max_depth);
        }

        props.showLoading(false);
    
    }

    function onChangeCurriBook(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeCurriBook", e.target.value);
        // getChildrenList(e.target.value, "CurriBook");
        getDescendantsList(e.target.value);

        CL_html_curriUnit1.selectedIndex = 0;
        CL_html_curriUnit2.selectedIndex = 0;
        CL_html_curriUnit3.selectedIndex = 0;
        CL_html_curriUnit4.selectedIndex = 0;
        CL_html_curriUnit5.selectedIndex = 0;

        setCurriUnit2([]);
        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit1(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit1", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit1");
        const _filtered_curriUnit2 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit2");
        const result = _filtered_curriUnit2.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit2(result);

        CL_html_curriUnit2.selectedIndex = 0;
        CL_html_curriUnit3.selectedIndex = 0;
        CL_html_curriUnit4.selectedIndex = 0;
        CL_html_curriUnit5.selectedIndex = 0;

        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit2(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit2", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit2");
        const _filtered_curriUnit3 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit3");
        const result = _filtered_curriUnit3.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit3(result);

        CL_html_curriUnit3.selectedIndex = 0;
        CL_html_curriUnit4.selectedIndex = 0;
        CL_html_curriUnit5.selectedIndex = 0;

        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit3(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit3", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit3");
        const _filtered_curriUnit4 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit4");
        const result = _filtered_curriUnit4.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit4(result);

        CL_html_curriUnit4.selectedIndex = 0;
        CL_html_curriUnit5.selectedIndex = 0;

        setCurriUnit5([]);
    }

    function onChangeUnit4(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit4", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit4");
        const _filtered_curriUnit5 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit5");
        const result = _filtered_curriUnit5.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit5(result);

        CL_html_curriUnit5.selectedIndex = 0;
    }

    function onChangeUnit5(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit5", e.target.value);
    }
    
    function onClickSDateIcon() : void {
        const new_CL_sdate = document.getElementById("new_CL_sdate") as HTMLInputElement;
        if(new_CL_sdate) {
            // new_CL_sdate.value = "";
            const CL_sdate = document.getElementById("CL_sdate") as HTMLInputElement;
            CL_sdate.value = "";
            CL_sdate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickSDate", div, div.length);
                    const CL_edate = document.getElementById("CL_edate") as HTMLInputElement;
                    CL_edate.click();
                }
            }, 10);    
        }
    }
        
    function onClickEDateIcon() : void {
        const new_CL_edate = document.getElementById("new_CL_edate") as HTMLInputElement;
        if(new_CL_edate) {
            // new_CL_edate.value = "";
            const CL_edate = document.getElementById("CL_edate") as HTMLInputElement;
            CL_edate.value = "";
            CL_edate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickEDate", div, div.length);
                    const CL_sdate = document.getElementById("CL_sdate") as HTMLInputElement;
                    CL_sdate.click();
                }
            }, 10);    
        }
    }

    function onChangeCalendar(e: { target: HTMLInputElement; }): void | undefined {
        logger.log("onChangeCalendar", e.target.id)

        if(e.target.id === "CL_sdate") {
            const new_CL_sdate = document.getElementById("new_CL_sdate") as HTMLInputElement;
            if(new_CL_sdate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_CL_sdate.value = val;
            }
        } else if(e.target.id === "CL_edate") {
            const new_CL_edate = document.getElementById("new_CL_edate") as HTMLInputElement;
            if(new_CL_edate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_CL_edate.value = val;
            }
        }
    }

    function onChangeViewType() {
        const viewlist1 = document.getElementById("rd-viewlist1") as HTMLInputElement;
        if(viewlist1.checked) {
            setViewType("s");    
        } else {
            setViewType("b");
        }        
    }

    function onPreView(item: ISet): void {
        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }

        let url = "";
        if(item) {
            url = EDITOR_HOST+"/editor/viewer/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&set_id="+item.id+"&token="+util.getUserInfo("token");
        } else {
            url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&create=y"+"&token="+util.getUserInfo("token");
        }
        window.open(url, "_blank", "noopener, noreferrer");
    }

    async function onChangePublicOpen(item: ISet) {
        const CL_is_publicOpen = document.getElementById("CL_is_publicOpen") as HTMLInputElement;
        if(CL_is_publicOpen) {

            props.showLoading(true);

            logger.log("CL_is_publicOpen.checked", item, CL_is_publicOpen.checked);

            const data = {
                id: item.id,
                is_publicOpen: !item.is_publicOpen,
                is_materialOpen: !item.is_publicOpen,
                loginUserId : util.getUserInfo("uid"),
            };

            logger.log(data);

            await util.setForSave(data, "update");

            _onSearch(searchData);
        }
    }

    async function onChangeMaterialOpen(item: ISet) {
        const CL_is_materialOpen = document.getElementById("CL_is_materialOpen") as HTMLInputElement;
        if(CL_is_materialOpen) {

            props.showLoading(true);

            logger.log("CL_is_materialOpen.checked", item, CL_is_materialOpen.checked);

            const data = {
                id: item.id,
                is_materialOpen: !item.is_materialOpen,
                loginUserId : util.getUserInfo("uid"),
            };

            logger.log(data);

            await util.setForSave(data, "update");

            _onSearch(searchData);
        }
    }
    
    function onReview(item: ISet) {
        if(selectedItem && selectedItem.review === 0) {
            setModalShow(true);
            setSelectedItem(item);
        }
    }

    async function onReviewOk() {
        if(selectedItem && selectedItem.review === 0) {    
            const data = {
                saveType: "update",
                article: {
                    id: selectedItem.id,
                    review: 1,
                    loginUserId : util.getUserInfo("uid"),
                },
            };

            logger.log(data);

            await util.updateArticle(data);
            _onSearch(searchData);
        }
    }

    function onReviewClose() {
        setModalShow(false);
    }

    function onEditPage(item: ISet | null): void {
        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }

        let url = "";
        if(item) {
            url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&set_id="+item.id+"&token="+util.getUserInfo("token");
        } else {
            url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&create=y"+"&token="+util.getUserInfo("token");
        }
        window.open(url, "_blank", "noopener, noreferrer");
    }

    async function onDelete() {
        setViewConfirmDelete(true);
        setDeleteTitle("삭제");
        setDeleteDesc("삭제시 복구가 불가능합니다. 진행 하시겠습니까? (본인이 저작한 수업자료만 삭제됩니다.)");
    }

    async function onDeleteOK() {

        onCloseDelete();

        logger.log("onDeleteOK");

        const sets : {"id": number}[] = [];
        const list_ck = document.getElementsByName("list_ck");
        const _sets = list as ISet[];

        let checkedCnt = 0;
        const me = util.getUserInfo("uid");
        for (let i = 0; i < list_ck.length; i++) {
            const element = list_ck[i] as HTMLInputElement;
            if(element.checked) {
                checkedCnt++;
                if(me === _sets[i].creator) {
                    const _data = {"id": _sets[i].id};
                    sets.push(_data);
                }
            }
        }

        const data = {"sets": sets, "is_active": false};
        logger.log(data);

        if(checkedCnt > 0 && sets.length === 0) {
            props.showModal("", "타 저작자가 만든 콘텐츠는 삭제할 수 없습니다.");
            return;
        } else if(checkedCnt === 0) {
            return;
        }

        props.showLoading(true);

        const result = await util.changeContentsActiveStatus(data);
        logger.log(data, result);

        props.showLoading(false);
        
        _onSearch(searchData);

        logger.log(checkedCnt, sets.length);
        if(checkedCnt === sets.length) {
            props.showModal("", "수업자료가 삭제되었습니다.");
        } else {
            props.showModal("", "본인이 저작한 수업자료가 삭제되었습니다. 타 저작자가 만든 수업자료는 삭제할 수 없습니다.");
        }
    }
    
    function onCloseDelete() {
        setViewConfirmDelete(false);
    }

    function onBulkInsertSet() {

    }

    async function onViewContentsUpdateHistory(item: ISet) {
        setSelectedItem(item);
        setViewContentsUpdateHistory(true);
    } 

    async function onChangeForCurriBook() {
        
        const brand = util.getCurrentBrand();

        const metaMap: {"name": string, "id": number}[] = [];

        const data = {
            "brand_id": brand?.id,
            "name":"curriBook",
            "metaMap": metaMap,
        }

        if(curriInfo) {

            const TE_curriYear = document.getElementById("TE_curriYear") as HTMLSelectElement;
            if(TE_curriYear) {
                const _curriYear = curriInfo.curriYear.find((meta) => meta.val === TE_curriYear.value)
                if(_curriYear) {
                    metaMap.push({"name":"curriYear", "id":Number(_curriYear.id)});
                }
            }
            const TE_curriSchool = document.getElementById("TE_curriSchool") as HTMLSelectElement;
            if(TE_curriSchool) {
                const _curriSchool = curriInfo.curriSchool.find((meta) => meta.val === TE_curriSchool.value)
                if(_curriSchool) {
                    metaMap.push({"name":"curriSchool", "id":Number(_curriSchool.id)});
                }
            }
            const TE_curriSubject = document.getElementById("TE_curriSubject") as HTMLSelectElement;
            if(TE_curriSubject) {
                const _curriSubject = curriInfo.curriSubject.find((meta) => meta.val === TE_curriSubject.value)
                if(_curriSubject) {
                    metaMap.push({"name":"curriSubject", "id":Number(_curriSubject.id)});
                }
            }
            const TE_curriGrade = document.getElementById("TE_curriGrade") as HTMLSelectElement;
            if(TE_curriGrade) {
                const _curriGrade = curriInfo.curriGrade.find((meta) => meta.val === TE_curriGrade.value)
                if(_curriGrade) {
                    metaMap.push({"name":"curriGrade", "id":Number(_curriGrade.id)});
                }
            }
        }

        data.metaMap = metaMap;
        
        props.showLoading(true);

        const list = await util.searchMeta(data);
        if(list) {
            setCurriBook(list);
        }

        logger.log("onChangeForCurriBook", data, curriInfo?.curriBook, list);

        props.showLoading(false);
    }

    return (
        <div>
            <div className="top-title">
                <h3>{common.leftmenu_2[1][currMenu.menu2]}</h3>
                {/* <button type="button" className="btn-sky" onClick={onBulkInsertSet}>수업자료(영상)일괄등록</button> */}
            </div>
                
            <div className=""  id="subject" role="tabpanel" aria-labelledby="subject-tab"> 
                
                {/* <!-- 검색영역 --> */}
                <div className="px-8 py-8 border border-gray-300 rounded-xl">
                    <div style={{display: util.getCurrentBrand()?.code === "VIVA01" ? "" : "none"}}>                            
                        <fieldset>
                            <label htmlFor="search-3">교육과정</label>
                            <select id="TE_curriYear" name="search-3" onChange={onChangeForCurriBook}>
                                <option value={""}>전체</option>
                                {curriInfo && curriInfo?.curriYear && (curriInfo?.curriYear as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </fieldset>
                        <fieldset>
                            <label htmlFor="search-4" className="ml-4">학교급</label>
                            <select id="TE_curriSchool" name="search-4" onChange={onChangeForCurriBook}>
                                <option value={""}>전체</option>
                                {curriInfo && curriInfo?.curriSchool && (curriInfo?.curriSchool as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </fieldset>
                        <fieldset>
                            <label htmlFor="search-4" className="ml-4">교과목</label>
                            <select id="TE_curriSubject" name="search-4" onChange={onChangeForCurriBook}>
                                <option value={""}>전체</option>
                                {curriInfo && curriInfo?.curriSubject && (curriInfo?.curriSubject as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </fieldset>
                    </div>                    
                    <div className="mt-3">
                        <fieldset>
                            <label htmlFor="curriBook">교과</label>
                            <select id="CL_curriBook" name="CL_curriBook" onChange={(e) => onChangeCurriBook(e)}>
                                <option value="0">교과</option>              
                                {curriBook && (curriBook as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.code}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>
                        <fieldset>
                            <label htmlFor="curriUnit1" className="ml-8">교과과정</label>
                            <select className="mr-2" id="CL_curriUnit1" name="curriUnit1" onChange={(e) => onChangeUnit1(e)} style={{display:curriDepth > 0 ? "" : "none"}}>
                                <option value="0">1Depth</option>              
                                {curriUnit1 && (curriUnit1 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="CL_curriUnit2" name="curriUnit2" onChange={(e) => onChangeUnit2(e)} style={{display:curriDepth > 1 ? "" : "none"}}>
                                <option value="0">2Depth</option>              
                                {curriUnit2 && (curriUnit2 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="CL_curriUnit3" name="curriUnit3" onChange={(e) => onChangeUnit3(e)} style={{display:curriDepth > 2 ? "" : "none"}}>
                                <option value="0">3Depth</option>              
                                {curriUnit3 && (curriUnit3 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="CL_curriUnit4" name="curriUnit4" onChange={(e) => onChangeUnit4(e)} style={{display:curriDepth > 3 ? "" : "none"}}>
                                <option value="0">4Depth</option>              
                                {curriUnit4 && (curriUnit4 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select id="CL_curriUnit5" name="curriUnit5" onChange={(e) => onChangeUnit5(e)} style={{display:curriDepth > 4 ? "" : "none"}}>
                                <option value="0">5Depth</option>              
                                {curriUnit5 && (curriUnit5 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>

                    </div>
                    <div className="mt-3">
                        <fieldset>
                            <label htmlFor="search-4">AIDT 수업자료 공개 여부</label>
                            <select id="CL_public" name="search-4">
                                <option value={""}>전체</option>
                                <option value={"1"}>공개</option>
                                <option value={"0"}>비공개</option>
                            </select>
                        </fieldset>
                        <fieldset>
                            <label htmlFor="search-5" className="ml-8">수업자료 유형</label>
                            <select id="CL_type" name="search-5">
                                <option value={""}>전체</option>
                                {curriInfo && (curriInfo.setCategory as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>
                        <fieldset>
                            <label htmlFor="search-5" className="ml-8">수업자료 난이도</label>
                            <select id="CL_difficulty" name="search-5">
                                <option value={""}>전체</option>
                                {curriInfo && (curriInfo.difficulty as IMeta[]).map((item, idx) => {

                                let visible = false;
                                
                                const brand = util.getCurrentBrand();
                                if(brand?.code === "VAMT01") {
                                    if(curriInfo.difficulty.length === 5) {
                                        if(idx === 0 || idx === 2 || idx === 4) {
                                            visible = true;
                                        }
                                    } else if(curriInfo.difficulty.length === 7) {
                                        if(idx === 0 || idx === 3 || idx === 6) {
                                            visible = true;
                                        }
                                    } else if(curriInfo.difficulty.length === 9) {
                                        if(idx === 0 || idx === 4 || idx === 8) {
                                            visible = true;
                                        }
                                    }    
                                } else {
                                    visible = true;
                                }

                                return(
                                <option key={idx} value={item.id} style={{display: visible ? "" : "none"}}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>

                        <fieldset>
                            <label htmlFor="date" className="ml-8">등록기간</label>
                        </fieldset>

                        <fieldset>
                            <DatePicker id='CL_sdate' name='CL_sdate' placeholder="시작일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                        </fieldset>

                        <fieldset>
                            <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={onClickSDateIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>                                            
                            </button> 
                        </fieldset>

                        <fieldset>
                            <span className="mx-3" style={{marginLeft: "0.5rem", marginRight: "0.5rem"}}>~</span> 
                        </fieldset>

                        <fieldset>
                            <DatePicker id='CL_edate' name='CL_edate' placeholder="종료일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                        </fieldset>

                        <fieldset>
                            <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={onClickEDateIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>                                            
                            </button> 
                        </fieldset>

                    </div>

                    <div className="text-left">
                        <fieldset>
                            <label htmlFor="search-4">AIDT 수업자료실 공개 여부</label>
                            <select id="CL_material" name="search-4">
                                <option value={""}>전체</option>
                                <option value={"1"}>공개</option>
                                <option value={"0"}>비공개</option>
                            </select>
                        </fieldset>
                    </div>

                    <div className="text-right">

                        <fieldset>
                            <label htmlFor="search-6">검색</label>
                            <select id="CL_keyword_type" name="search-6">
                                <option value={"name"}>작업 명(수업자료 명)</option>
                                <option value={"id"}>KEY</option>
                                <option value={"creator"}>등록자</option>
                            </select>
                            <input type="text" name="search" id="CL_keyword" className="w-[300px] ml-3" onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch();
                                }
                            }} />
                            <button type="button" className="h-[36px] btn-sky-s ml-3" onClick={onSearch}>
                                <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                            </button>
                        </fieldset>
                    </div>                        

                    {/* <div className="mt-3 text-right">
                        <fieldset>
                            <label htmlFor="search-6">작업 명(수업자료 명)</label>
                            <input type="text" name="search" id="CL_keyword" className="w-[300px] ml-3" />
                            <button type="button" className="h-[36px] btn-sky-s ml-3" onClick={onSearch}>
                                <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                            </button>
                        </fieldset>
                    </div>                         */}
                </div>
                {/* <!-- .//검색영역 --> */}

                <div className="mt-6 mb-3">
                    <form action="" className="flex justify-between">
                        <fieldset>
                            <span>총 <strong className="text-red">{listCount}</strong>건</span>
                            <label htmlFor="page-num" className="ml-3">페이지당 표기 수</label>
                            <select id="pagenum" name="page-num" onChange={onChangeNumOf1Page}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={100}>100</option>
                            </select>
                        </fieldset>
                        <fieldset aria-labelledby="radiogruop" className="mt-2 items-center">
                            {/* <button type="button" className="btn-white mr-5" onClick={onDelete}>삭제</button> */}
                            <input type="radio" id="rd-viewlist1" name="rd-viewlist" className="items-center" onChange={onChangeViewType} />
                            <label htmlFor="rd-viewlist1" className="items-center ml-1">간단히</label>
                            <input type="radio" id="rd-viewlist2" name="rd-viewlist" className="items-center" onChange={onChangeViewType}/>
                            <label htmlFor="rd-viewlist2" className="items-center ml-1">큰 아이콘</label>
                        </fieldset>
                    </form>
                    
                </div>

                {/* <!-- table --> */}
                {viewList && viewType === "s" &&
                <table>
                    <caption className="sr-only">교과 콘텐츠 테이블</caption>
                    <thead>
                        <tr>
                            <th style={{display:""}} scope="col" className="pl-4 pr-3">
                                <input id="all_check" className="h-4 w-4 rounded mt-2" type="checkbox" onClick={(e) => {
                                const list_ck = document.getElementsByName("list_ck");
                                for (let i = 0; i < list_ck.length; i++) {
                                    const element = list_ck[i] as HTMLInputElement;
                                    if(!element.disabled) {
                                        element.checked = (e.target as HTMLInputElement).checked;
                                    }                                    
                                }
                            }} /></th>
                            <th scope="col" className="pl-4 pr-3">번호</th>
                            <th scope="col" className="pl-4 pr-3">Key</th>
                            <th scope="col" className="px-3">교과</th>
                            {/* <th scope="col" className="px-3">교육과정 표준체게</th> */}
                            <th scope="col" className="px-3">작업 명(수업자료 명)</th>
                            <th scope="col" className="px-3">수업자료 유형</th>
                            <th scope="col" className="px-3">수업자료 난이도</th>
                            <th scope="col" className="px-3">등록일</th>
                            <th scope="col" className="px-3">수정기록</th>
                            <th scope="col" className="px-3">등록자</th>
                            <th scope="col" className="px-3">미리보기</th>
                            <th scope="col" className="px-3">편집</th>
                            <th scope="col" className="px-3">AIDT 수업자료 공개설정</th>
                            <th scope="col" className="px-3">AIDT 수업자료실 공개상태</th>
                            {/* <th scope="col" className="px-3">배포이력</th> */}
                        </tr>
                    </thead>
                    <tbody>

                    {viewList && list &&
                        <tr style={{display: (list as ISet[]).length === 0 ? "" : "none"}}>
                            <td colSpan={13}>{searchData ? "등록된 오브젝트가 없습니다." : "조회할 데이터를 검색해 주세요."}</td>
                        </tr>}

                    {viewList && list && (list as ISet[]).map((item, idx) => {

                        let _unitVal = "";
                        let _curriBookVal = ""

                        if(curriInfo && curriInfo.curriBook) {
                            const curriBook = curriInfo.curriBook as IMeta[];
                            const _curriBook = curriBook.find((book, _) => book.id === item.curriBook);
                            _curriBookVal = _curriBook ? _curriBook.val : "";

                            // const curriUnit1 = curriInfo.curriUnit1 as IMeta[];
                            // const curriUnit2 = curriInfo.curriUnit2 as IMeta[];
                            // const curriUnit3 = curriInfo.curriUnit3 as IMeta[];
                            // const curriUnit4 = curriInfo.curriUnit4 as IMeta[];
                            // const curriUnit5 = curriInfo.curriUnit5 as IMeta[];

                            // const _curriUnit1 = curriUnit1.find((unit, _) => unit.id === item.curriUnit1);
                            // const _curriUnit2 = curriUnit2.find((unit, _) => unit.id === item.curriUnit2);
                            // const _curriUnit3 = curriUnit3.find((unit, _) => unit.id === item.curriUnit3);
                            // const _curriUnit4 = curriUnit4.find((unit, _) => unit.id === item.curriUnit4);
                            // const _curriUnit5 = curriUnit5.find((unit, _) => unit.id === item.curriUnit5);

                            // const arr = [_curriUnit1?.val, _curriUnit2?.val, _curriUnit3?.val, _curriUnit4?.val, _curriUnit5?.val];
                            // arr.map((val, idx) => {
                            //     if(val) {
                            //         if(_unitVal === "") {
                            //             _unitVal = val;    
                            //         } else {
                            //             if(idx === 2) {
                            //                 _unitVal = _unitVal + " > " + "<br/>" + val;

                            //             } else {
                            //                 _unitVal = _unitVal + " > " + val;
                            //             }
                            //         }
                            //     }
                            // });
                        }

                        const totalCnt = item.full_count;
                        const _numOfPage = localStorage.getItem("Contents_numOfPage") ? Number(localStorage.getItem("Contents_numOfPage")) : 10;
                        let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                        let updateDate = "-";
                        if(item.updatedate) {
                            updateDate = item.updatedate.substring(0, 10).replaceAll("-", ".");
                        }
                        
                        let hashTag = "";
                        const arrHashTag = item.hashTag as string[];
                        if(arrHashTag) {
                            arrHashTag.map((item) => {
                                if(item.trim() !== "") {
                                    hashTag = hashTag + (item[0] !== "#" ? "#" : "") + item + " ";
                                }
                            });    
                        }

                        let _setCategoryVal = ""
                        if(curriInfo && curriInfo.setCategory) {

                            const setCategory = curriInfo.setCategory as IMeta[];
                            const _setCategory = setCategory.find((meta, _) => meta.id === item.setCategory);

                            _setCategoryVal = String(_setCategory?.val);
                        }

                        let _difficultyVal = ""
                        if(curriInfo && curriInfo.difficulty) {

                            const difficulty = curriInfo.difficulty as IMeta[];
                            const _difficulty = difficulty.find((meta, _) => meta.id === Number(item.difficulty));

                            _difficultyVal = String(_difficulty?.val);
                        }

                        const name = item.name && item.name.length > 20 ? item.name.substring(0, item.name.length/2) + "<br/>" + item.name.substring(item.name.length/2) : item.name;

                        let deployStatus = "-";
                        if(item.deployStatus === 1) {
                            deployStatus = "배포대기";
                        } else if(item.deployStatus === 3) {
                            deployStatus = "배포보류";
                        } else if(item.deployStatus === 10) {
                            deployStatus = "배포중";
                        } else if(item.deployStatus === 20) {
                            deployStatus = "배포실패";                            
                        }

                        const hasAuth = util.getHasAuthMenu(item.creator ? item.creator : "");

                        return (

                            <tr key={idx}>
                                <td style={{display:""}}>
                                    <input id={"list_ck_"+idx} name="list_ck" type="checkbox" className="h-4 w-4 rounded mt-2" onClick={(e) => {
                                        const list_ck = document.getElementsByName("list_ck");
                                        let cntChecked = 0;
                                        for (let i = 0; i < list_ck.length; i++) {
                                            const element = list_ck[i] as HTMLInputElement;
                                            if(element.checked) {
                                                cntChecked = cntChecked + 1;
                                            }
                                        }
                                        const all_check = document.getElementById("all_check") as HTMLInputElement;
                                        if(cntChecked === list_ck.length) {                                                
                                            all_check.checked = true;                                                
                                        } else {
                                            all_check.checked = false;                                                
                                        }
                                    }}/>
                                </td>
                                <td>{_num}</td>
                                <td>{item.id}</td>
                                <td>{_curriBookVal}</td>
                                {/* <td dangerouslySetInnerHTML={{ __html: _unitVal }}></td> */}
                                <td style={{fontSize: "0.8em"}} dangerouslySetInnerHTML={{ __html: name }}></td>
                                <td>{_setCategoryVal}</td>
                                <td>{_difficultyVal}</td>
                                <td style={{fontSize: "0.8em"}}>{item.regdate.substring(0, 10).replaceAll("-", ".")}</td>
                                <td style={{fontSize: "0.8em"}}>
                                    {/* {updateDate} */}
                                    <button type="button" className="btn-white-s" onClick={() => onViewContentsUpdateHistory(item)}>확인</button>
                                </td>
                                <td style={{fontSize: "0.8em"}}>{item.creator}</td>
                                <td><button type="button" className="btn-white-s" onClick={() => {
                                    if(item.is_publicOpen) {                                            
                                        onPreView(item);
                                    } else {                                                
                                        if(!hasAuth) {
                                            props.showModal("", "타인이 비공개한 수업자료라 확인 불가 합니다.");
                                        } else {
                                            onPreView(item);
                                        }
                                    }                                    
                                }}>미리보기</button></td>
                                <td>
                                    <button type="button" className="btn-white-s" disabled={false} onClick={() => {
                                        if(item.is_publicOpen) {                                            
                                            onEditPage(item);
                                        } else {                                                
                                            if(!hasAuth) {
                                                props.showModal("", "타인이 비공개한 수업자료라 확인 불가 합니다.");
                                            } else {
                                                onEditPage(item);
                                            }
                                        }                                                                            
                                    }}>편집</button>
                                    {/* <MyButton type="button" className="btn-white-s" creator={item.creator} onClick={() => onEditPage(item)} showModal={props.showModal} children={"편집"} /> */}
                                </td>
                                <td>
                                    <label className="inp-toggle" style={{display: hasAuth ? "" : "none"}}>
                                        <input type="checkbox" id="CL_is_publicOpen" value="" className="peer" defaultChecked={item.is_publicOpen} onChange={() => onChangePublicOpen(item)} disabled={!hasAuth}/>
                                        <div className={"peer peer-checked:after:translate-x-full peer-checked:after:border-white " + (hasAuth ? "peer-checked:bg-sky-500" : "peer-checked:bg-sky-50")} style={{cursor: hasAuth ? "pointer" : "auto"}}></div>
                                    </label>
                                    <label className="inp-toggle" style={{display: !hasAuth ? "" : "none", cursor: "auto"}}>
                                        {item.is_publicOpen ? "공개" : "비공개"}
                                    </label>
                                </td>
                                <td>
                                    {item.is_materialOpen ? "공개" : "비공개"}
                                    {/* <label className="inp-toggle">
                                        <input type="checkbox" id="CL_is_materialOpen" value="" className="peer" defaultChecked={item.is_materialOpen} onChange={() => onChangeMaterialOpen(item)} disabled={!hasAuth}/>
                                        <div className={"peer peer-checked:after:translate-x-full peer-checked:after:border-white " + (hasAuth ? "peer-checked:bg-sky-500" : "peer-checked:bg-sky-50")} style={{cursor: hasAuth ? "pointer" : "auto"}}></div>
                                    </label> */}
                                </td>

                                {/* <td>{deployStatus}</td> */}

                            </tr>
                        )
                        })}

                    </tbody>
                </table>}
                {/* <!-- .//table -->	 */}

                {viewList && viewType === "b" &&
                <ul>

                    {viewList && list &&
                        <li style={{display: (list as ISet[]).length === 0 ? "" : "none"}}>
                            <div role="label" aria-labelledby="edit-contents1" className="w-full flex">
                                {/* <p>등록된 오브젝트가 없습니다.</p> */}
                            </div>
                        </li>}

                    {viewList && list && (list as ISet[]).map((item, idx) => {

                        let _unitVal = "";
                        let _curriBookVal = ""

                        if(curriInfo && curriInfo.curriBook) {
                            const curriBook = curriInfo.curriBook as IMeta[];
                            const _curriBook = curriBook.find((book, _) => book.id === item.curriBook);
                            _curriBookVal = _curriBook ? _curriBook.val : "";

                            // const curriUnit1 = curriInfo.curriUnit1 as IMeta[];
                            // const curriUnit2 = curriInfo.curriUnit2 as IMeta[];
                            // const curriUnit3 = curriInfo.curriUnit3 as IMeta[];
                            // const curriUnit4 = curriInfo.curriUnit4 as IMeta[];
                            // const curriUnit5 = curriInfo.curriUnit5 as IMeta[];

                            // const _curriUnit1 = curriUnit1.find((unit, _) => unit.id === item.curriUnit1);
                            // const _curriUnit2 = curriUnit2.find((unit, _) => unit.id === item.curriUnit2);
                            // const _curriUnit3 = curriUnit3.find((unit, _) => unit.id === item.curriUnit3);
                            // const _curriUnit4 = curriUnit4.find((unit, _) => unit.id === item.curriUnit4);
                            // const _curriUnit5 = curriUnit5.find((unit, _) => unit.id === item.curriUnit5);

                            // const arr = [_curriUnit1?.val, _curriUnit2?.val, _curriUnit3?.val, _curriUnit4?.val, _curriUnit5?.val];
                            // arr.map((val, idx) => {
                            //     if(val) {
                            //         if(_unitVal === "") {
                            //             _unitVal = val;    
                            //         } else {
                            //             if(idx === 2) {
                            //                 _unitVal = _unitVal + " > " + "<br/>" + val;

                            //             } else {
                            //                 _unitVal = _unitVal + " > " + val;
                            //             }
                            //         }
                            //     }
                            // });
                        }

                        const totalCnt = item.full_count;
                        const _numOfPage = localStorage.getItem("Contents_numOfPage") ? Number(localStorage.getItem("Contents_numOfPage")) : 10;
                        let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                        let updateDate = "-";
                        if(item.updatedate) {
                            updateDate = item.updatedate.substring(0, 10).replaceAll("-", ".");
                        }

                        let thumb = "http://placehold.it/150x150.png/aaaaaa/ffffff";
                        if(item.articles) {
                            thumb = item.thumbnail ? item.thumbnail : item.articles.length === 0 ? "http://placehold.it/150x150.png/aaaaaa/ffffff" : S3Server+item.articles[0].thumbnail;                        
                        }

                        let _setCategoryVal = ""
                        if(curriInfo && curriInfo.setCategory) {

                            const setCategory = curriInfo.setCategory as IMeta[];
                            const _setCategory = setCategory.find((meta, _) => meta.id === item.setCategory);

                            _setCategoryVal = String(_setCategory?.val);
                        }

                    return (

                        <li className="flex items-start" key={idx}>
                            <div role="label" aria-labelledby="edit-contents1" className="w-full flex">
                                <span className="inline-block mx-3 text-lg">{_num}</span>
                                <div className="flex relative p-4 border border-slate-300 flex-grow justify-between items-center">
                                    <div className="flex items-center">
                                        <div className="overflow-hidden">
                                            <img src={thumb} style={{width:"150px", cursor:"pointer"}} alt="썸네일" onClick={() => onPreView(item)} />
                                        </div>
                                    </div>
                                    
                                    <ul className="mr-40 w-96 list-disc mt-10">
                                        <li><strong className="font-normal">{_curriBookVal}</strong></li>
                                        <li><strong className="font-normal" dangerouslySetInnerHTML={{ __html: _unitVal }}></strong> </li>
                                        <li>
                                            <strong className="font-normal">작업 명(수업자료 명) :</strong>
                                            <span>{item.name}</span>
                                        </li>
                                        <li>
                                            <strong className="font-normal">수업자료 유형 :</strong>
                                            <span>{_setCategoryVal}</span>
                                        </li>
                                    </ul>
                                    <div className="absolute top-4 right-28">
                                        <strong className="font-normal">등록일 :</strong>
                                        <span>{item.regdate.substring(0, 10).replaceAll("-", ".")}</span>
                                        <em>/</em>
                                        <strong className="font-normal">수정일 :</strong>
                                        <span>{updateDate}</span>
                                    </div>

                                    <div className="absolute right-4 top-4">
                                        <button type="button" aria-label="수정" className="disabled:bg-transparent disabled:text-slate-300" disabled={false}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                            </svg>
                                        </button>
                                        <label className="inp-toggle">
                                            <input type="checkbox" value="" className="peer" defaultChecked={item.is_publicOpen} />
                                            <div className="peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:bg-sky-500"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </li>
                        )
                    })}

                </ul>}

                <div className="relative mt-5">

                    {/* <!-- pagenation --> */}
                    <div className="text-center">
                        <nav className="pagenation" aria-label="Pagination">

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Previous</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                </svg>
                            </div>}

                            {listOfPage && (listOfPage as number[]).map((item, idx) => {
                            return (
                                currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                            )})}

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Next</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                </svg>
                            </div>}
                            
                        </nav>
                    </div>
                    {/* <!-- .// pagenation --> */}
                </div>
            </div>

            <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true" style={{display : modalShow ? "" : "none"}}>
                <div className="dim"></div>
                <div className="popup-contents">
                    <div className="popup-contents-inner">
                        <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                        <div className="popup-box max-w-xl min-w-[576px]">
                            {/* <!-- 팝업 내용 --> */}
                            <div className="inner">
                                <button type="button" className="btn-close" onClick={onReviewClose}>
                                    <span className="sr-only">Close</span>
                                    <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                                <h2 className="pop-title">심사 확인</h2>
                                <div className="px-5">
                                    <div className="py-8 text-center">
                                        <p>심사 완료된 콘텐츠로 변경 하시겠습니까?</p>
                                    </div>
                                </div>
                                <div className="my-5 text-center">
                                    <button type="button" className="btn-sky" onClick={onReviewOk}>확인</button>

                                </div>
                            </div>
                            {/* <!-- .//팝업 내용 --> */}
                        </div>
                    </div>
                </div>
            </div>

            {viewContentsUpdateHistory &&
            <PopupContentsUpdateHistory selectedItem={selectedItem} isSet={true} onClose={() => {
                setSelectedItem(null);
                setViewContentsUpdateHistory(false);
            }} showModal={props.showModal} showLoading={props.showLoading} />}

            <PopupConfirm view={viewConfirmDelete} title={deleteTitle} description={deleteDesc} onClose={onCloseDelete} onOk={onDeleteOK} showModal={props.showModal} />

        </div>
    );  
}
export default ContentsSetList;
